<template>
  <v-container fluid class="grey lighten-3 pa-0">
    <!-- PUBLISH ALERT -->
    <v-alert
      :value="view.admin && round && !round.published"
      transition="scale-transition"
      type="warning"
      prominent tile
      class="mb-0"
    >
        <v-row align="center">
          <v-col class="grow">
            <strong class="black--text">{{division.name}} {{round.name}} are not published. </strong>
          </v-col>
          <v-col class="shrink">
            <v-btn
              @click.stop="publish"
              :loading="loading"
            >publish</v-btn>
          </v-col>
        </v-row>
    </v-alert>
    <v-row no-gutters>
      <v-col cols="12" v-if="view.admin" class="text-end">
        <v-btn
          color="color3"
          text
          class="xsmall my-0 pl-1"
          :class="{'mx-0': $vuetify.breakpoint.xsOnly}"
          @click.stop="settings = !settings"
        >
          <v-icon>fas fa-cog</v-icon>
        </v-btn>
      </v-col>
      <v-expand-transition>
        <v-col v-if="settings" cols="12">
          <v-card flat class="px-3">
            <round-settings
              :round="round"
              :fromSelect="fromSelections"
              :divisionId="division.id"
              :locked="tournament.locked || division.complete"
            ></round-settings>
          </v-card>
        </v-col>
      </v-expand-transition>
      <v-col cols="12">
        <v-window v-model="window" touchless>
          <v-window-item :value="0">
            <bracket-display
              v-if="bracket && window === 0"
              :key="`${this.bracket.id}_${this.round.published ? 1 : 0}`"
              :bracket="bracket"
              :showWinners="winners"
              :round="round"
              :active="$route.name === 'bracket-home'"
              :courting="courting"
              @match-click="onMatchClick"
              @dualSelected="onDualSelected"
            ></bracket-display>
          </v-window-item>
          <v-window-item :value="1">
            <dual-card
              v-if="dual"
              :dual="dual"
              :bracketCard="true"
              :bracket="bracket"
              :myKey="bracket.id"
              :round="round"
              :division="division"
            ></dual-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import BracketDisplay from '@/components/Tournament/Brackets/BracketDisplay.vue'
import { mapGetters } from 'vuex'
const DualCard = () => import('@/components/Tournament/Duals/DualCard.vue')
const RoundSettings = () => import('@/components/Tournament/RoundSettings/RoundSettings.vue')

export default {
  mixins: [RouteMixin],
  props: ['winners', 'courting'],
  data () {
    return {
      loading: false,
      window: 0,
      settings: false
    }
  },
  computed: {
    ...mapGetters([
      'admin',
      'tournament',
      'getDivision',
      'view'
    ]),
    bKey () {
      return `${this.bracket.id}_${this.round.published ? 1 : 0}`
    },
    division () {
      return this.getDivision(this.divisionId)
    },
    round () {
      if (!this.division) return null
      return this.division.days.find(f => f.id === this.dayId)
    },
    bracket () {
      if (!this.round) return null
      return this.round.brackets[0]
    },
    hasRegSelector () {
      return this.round.hasRegSelector
    },
    fullySeeded () {
      return this.division.isFullySeeded
    },
    dual () {
      return this.dualN && this.bracket && this.bracket.duals.find(f => f.n === this.dualN)
    },
    dualN () {
      return +this.$route.query.dual
    },
    fromSelections () {
      return this.division.days.map(d => {
        return {
          id: d.id,
          number: d.number,
          poolPlay: d.poolPlay,
          bracketPlay: d.bracketPlay,
          text: `${d.name} Results`,
          i: null
        }
      })
    }
  },
  methods: {
    onMatchClick (match) {
      this.$emit('match-click', match)
    },
    onDualSelected (dualN) {
      this.dualN = dualN
      this.window = 1
    },
    publish () {
      this.loading = true
      this.round.patch({
        published: true
      })
        .then(() => { this.round.published = true })
        .catch(err => { console.log(err.response) })
        .finally(() => { this.loading = false })
    },
    setWindow () {
      this.window = this.dualN ? 1 : 0
    }
  },
  watch: {
    dualN: 'setWindow'
  },
  mounted () {
    this.setWindow()
  },
  components: {
    BracketDisplay,
    DualCard,
    RoundSettings
  }
}
</script>

<style scoped>
</style>
